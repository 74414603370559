import { Field } from '../../../types/Field';

// Utility module for shared logic
const NumberFacetUtils = {
    createFilter(facetSelection: any, field: Field) {
        // facetSelection is an array of int values.
        const filterClauses = facetSelection.map((selection: any) => field.systemSettings.filterClause.replace('{{VALUE}}', selection).replace('{{OPERATOR}}', 'eq'));

        if (filterClauses.length === 0) {
            return "";
        }

        const filterClausesString = filterClauses.join(' and ');    
        return `(${filterClausesString})`;    
    },

    createRequest(field: Field) {
        return field.name;
    },

    extractFacetValues(facetValuesFromSearch: any) {
        let extractedValues: any = {};

        facetValuesFromSearch.forEach((facetValue: any) => {
            extractedValues[facetValue.value] = {
                count: facetValue.count
            };
        });

        return extractedValues;
    },

    createUpdatedSelection(facetValue: any, prevFacetSelection: any) {
        prevFacetSelection = prevFacetSelection || [];

        const isValueCurrentlySelected = prevFacetSelection.some((item: any) => item === facetValue);
        const newSelectionsOfFacet = isValueCurrentlySelected ?
            prevFacetSelection.filter((item: any) => item !== facetValue) : // Remove item
            [...prevFacetSelection, facetValue]; // Add item
    
        return newSelectionsOfFacet;
    }
};

export default NumberFacetUtils;