import { Field } from '../../../types/Field';
import NumberFacet from './NumberFacetPresentation';
import NumberFacetUtils from './NumberFacetUtils';
import { FacetLogic } from './Types';

function renderFacet (field: Field, facetValue: any, facetSelection: any, onChanged: any) { 
    return <NumberFacet 
              key={field.name} 
              name={field.name} 
              displayName={field.userSettings.displayName} 
              facetData={facetValue} 
              facetSelection={facetSelection}
              onChangeFacet={onChanged}/>          
}

export const createFacetLogic = (dataType: string): FacetLogic => ({
    dataType: dataType,
    createFilter: NumberFacetUtils.createFilter,
    createRequest: NumberFacetUtils.createRequest,
    extractFacetValues: NumberFacetUtils.extractFacetValues,
    renderFacet: renderFacet,
    createUpdatedSelection: NumberFacetUtils.createUpdatedSelection,
});

// Usage
export const int32FacetLogic = createFacetLogic('Edm.Int32');
export const int64FacetLogic = createFacetLogic('Edm.Int64');
export const doubleFacetLogic = createFacetLogic('Edm.Double');