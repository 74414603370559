import { durationFacetLogic } from './durationFacetLogic';
import { doubleFacetLogic, int32FacetLogic, int64FacetLogic } from './numberFacetLogic';
import { stringFacetLogic, stringArrayFacetLogic } from './stringFacetLogic';
import { timeFacetLogic } from './timeFacetLogic';
import { geographyPointsFacetLogic } from './geographyPointsFacetLogic';

const facetsDictionary = {    
    [durationFacetLogic.dataType]: durationFacetLogic,
    [int32FacetLogic.dataType]: int32FacetLogic,
    [int64FacetLogic.dataType]: int64FacetLogic,
    [doubleFacetLogic.dataType]: doubleFacetLogic,
    [stringFacetLogic.dataType]: stringFacetLogic,
    [stringArrayFacetLogic.dataType]: stringArrayFacetLogic,
    [timeFacetLogic.dataType]: timeFacetLogic,
    [geographyPointsFacetLogic.dataType]: geographyPointsFacetLogic
}

function getFacetLogicByType(dataType: string) {
    return facetsDictionary[dataType];
}

export const facets = {
    getFacetLogicByType: getFacetLogicByType
}