import * as timeDisplay from './timeDisplay';

const processResults = (results) => {
    return results.map(result => processResult(result.document, result.score, result.playAllowed));
}

const processResult = (result, score, playAllowed) => {
    result.startTimeText = timeDisplay.dateTimeStringToDisplayString(result.timeStart);
    result.endTimeText = timeDisplay.dateTimeStringToDisplayString(result.timeStop);

    result.durationText = timeDisplay.durationToDisplayString(result.duration);
    
    const ownerName = getDisplayNameOrPhonenumber(result.owner);

    let participantsExceptOwner = result.participants.filter(item => item.participantId !== result.owner.participantId);
    if (participantsExceptOwner.length === 1) {
        // A-B call, show the topline in the form '<CallingUser> to <CalledUser>'
        const otherParticipantName = getDisplayNameOrPhonenumber(participantsExceptOwner[0]);
        
        result.showArrow = true;
        result.ownerName = ownerName;
        result.otherParticipantName = otherParticipantName;

        if (result.direction === 'incoming') {
            result.showLeftArrow = true;            
        } else {
            result.showRightArrow = true;
        }
      
        result.subline = "";
    } else if (participantsExceptOwner.length === 0) {
        result.topLine = `${ownerName}`;
        result.subline = "";        
    } else {
        result.topLine = `${ownerName} and ${participantsExceptOwner.length} others`;
        result.subline = participantsExceptOwner.map(item => getDisplayNameOrPhonenumber(item)).join(', ');        
    }

    if (result.mediaTypes) {
        result.hasNoAudio = result.mediaTypes.indexOf('audio') < 0;
        result.hasVideo = result.mediaTypes.indexOf('video') > -1;
        result.hasScreenSharing = result.mediaTypes.indexOf('screen') > -1;    
    }
    else {
        // This should not happen...
        console.log(`No media types found in call with Id ${result.mediaId}`);
    }
   
    result.score = score;
    result.playAllowed = playAllowed;
    
    return result;
};

const getDisplayNameOrPhonenumber = (item) => {
    return item.displayName?.length > 0 ? item.displayName : item.phoneNumber;
}

export const resultsProcessor = {
    processResults: processResults
}